import { getHeaderAndFooterDetailsWithDefaultLocale } from '.';

const SetBrandsContext = async (brand: any = 'se', locale: any = 'en') => {
  const result: any = await Promise.all([
    getHeaderAndFooterDetailsWithDefaultLocale(brand, locale),
  ]);

  const data = (await result[0]?.data) || null;
  return data;
};

export default SetBrandsContext;
